export const ActivationStates = {
    INVALID_URL:"INVALID_URL",
    NOT_STARTED:"NOT_STARTED",
    SUCCESS:"SUCCESS",
    FAILURE:"FAILURE",
    LOCKED:"LOCKED",
    INVALID_USER:"INVALID_USER",
    DUPLICATE_LOGIN_CREDENTIAL: "DUPLICATE_LOGIN_CREDENTIAL",
    INCORRECT_PIN: "INCORRECT_PIN",
    INVALID_USER_STATUS: "INVALID_USER_STATUS",
    INCORRECT_LOGIN : "INCORRECT_LOGIN",
    MSA_EMAIL : "MSA_EMAIL",
    RESEND_ACTIVATION_EMAIL_SUCCESS : "RESEND_ACTIVATION_EMAIL_SUCCESS",
    SUSPENDED_USER : "SUSPENDED_USER"
}

export const MessageCodesToStatusMapping = {
    "MSG_1_1_10": ActivationStates.INCORRECT_PIN,
    "MSG_1_1_11":ActivationStates.LOCKED,
    "MSG_1_1_12":ActivationStates.DUPLICATE_LOGIN_CREDENTIAL,
    "MSG_1_1_13":ActivationStates.INVALID_USER_STATUS
    //"MSG_1_1_14":ActivationStates.INCORRECT_LOGIN
}