import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const SET_ACTIVATION_STATUS = "ACTIVATION/SET_ACTIVATION_STATUS";
export const setActivationStatus = makeActionCreator(SET_ACTIVATION_STATUS, true, "status");

export const CHECK_ACTIVATION = "ACTIVATION/CHECK_ACTIVATION";
export const checkActivation = makeActionCreator(CHECK_ACTIVATION,true,"pin","securityGuid");

export const CHECK_USER_ACCEPTANCE = "ACTIVATION/CHECK_USER_ACCEPTANCE";
export const checkUserAcceptance = makeActionCreator(CHECK_USER_ACCEPTANCE,true,"securityGuid");

export const SET_USER_ACCEPTANCE = "ACTIVATION/SET_USER_ACCEPTANCE";
export const setUserAcceptance = makeActionCreator(SET_USER_ACCEPTANCE, true, "status");

export const SET_DUPLICATE_ACCOUNT_EMAIL = "ACTIVATION/SET_DUPLICATE_ACCOUNT_EMAIL";
export const setDuplicateAccountEmail = makeActionCreator(SET_DUPLICATE_ACCOUNT_EMAIL, true, "email");

export const SET_USER_ACCOUNT_STATUS = "ACTIVATION/SET_USER_ACCOUNT_STATUS";
export const setUserAccountStatus = makeActionCreator(SET_USER_ACCOUNT_STATUS, true, "userAccountStatus");

export const SET_EXPECTED_CONTACT_EMAIL = "ACTIVATION/SET_EXPECTED_CONTACT_EMAIL";
export const setExpectedContactEmail = makeActionCreator(SET_EXPECTED_CONTACT_EMAIL, true, "expectedContactEmail");

export const SET_USER_PROFILE_ID = "ACTIVATION/SET_USER_PROFILE_ID";
export const setUserProfileId = makeActionCreator(SET_USER_PROFILE_ID, true, "userProfileId");

export const CHECK_RESEND_ACTIVATION_EMAIL = "ACTIVATION/CHECK_RESEND_ACTIVATION_EMAIL";
export const checkResendActivationEmail = makeActionCreator(CHECK_RESEND_ACTIVATION_EMAIL,true,"securityGuid");

export const SET_MASKED_EMAIL = "ACTIVATION/SET_MASKED_EMAIL";
export const setMaskedEmail = makeActionCreator(SET_MASKED_EMAIL, true, "maskedEmail");
