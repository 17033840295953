import { combineReducers } from 'redux';
import {fromJS} from 'immutable';

import * as CoreReducers from '@ec-oem/ec.oem.oa3.ui.core/reducers';
import * as CommonReducers from '@ec-oem/ec.oem.oa3.ui.common/reducers';
import * as Reducers from '../reducers';
import * as ActivationReducers from '../../Activation/reducers';


const reducerList = { ...CoreReducers, ...CommonReducers, ...Reducers,...ActivationReducers };

export const combineAppReducers = (config) => {
    const defaultState = fromJS(Object.keys(config).reduce((a, key) => { a[key] = config[key](undefined, []); return a }, {}));
    return (state = defaultState, action) => {
        return Object.keys(config).reduce((state, key) => {
            const reducer = config[key];
            const previousState = state.get(key);
            const newValue = reducer(previousState, action);
            if (newValue === undefined) {
                throw new Error(`A reducer returned undefined when reducing key::"${key}"`);
            }
            return state.set(key, newValue);
        }, state);
    };
}

export const reducers = combineReducers({
    app: combineAppReducers(reducerList),
});
