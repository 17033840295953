import { createSelector } from 'reselect';
import { userProfileId } from './ActivateUser.reducers';

export const currentActivationStatusSelector = createSelector(
    state => state.app.get("currentActivationStatus"),
    currentActivationStatus => currentActivationStatus ? currentActivationStatus : null
);

export const duplicateEmailSelector = createSelector(
    state => state.app.get("duplicateEmail"),
    duplicateEmail => duplicateEmail ? duplicateEmail : null
);

export const userAccountStatusSelector = createSelector(
    state => state.app.get("userAccountStatus"),
    userAccountStatus => userAccountStatus ? userAccountStatus : null
);

export const expectedContactEmailSelector = createSelector(
    state => state.app.get("expectedContactEmail"),
    expectedContactEmail => expectedContactEmail ? expectedContactEmail : null
);

export const userProfileIdSelector = createSelector(
    state => state.app.get("userProfileId"),
    userProfileId => userProfileId ? userProfileId : null
)

export const maskedEmailSelector = createSelector(
    state => state.app.get("maskedEmail"),
    maskedEmail => maskedEmail ? maskedEmail : null
)