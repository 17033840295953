
import { take, put,call,apply, select, all } from "redux-saga/effects";
import { CHECK_ACTIVATION, setActivationStatus, CHECK_USER_ACCEPTANCE, setExpectedContactEmail,setUserAcceptance, setDuplicateAccountEmail, setUserAccountStatus, setUserProfileId, CHECK_RESEND_ACTIVATION_EMAIL, setMaskedEmail} from "../../actions";
import { ActivationStates, MessageCodesToStatusMapping } from "./ActivateUser.constants";
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.common/utility";
import { default as  ApplicationConfig } from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { RequestHeaderConstants } from "@ec-oem/ec.oem.oa3.ui.common/constants/CoreConstants";
import {ActivateUserUtil} from './ActivateUser.Utils';
import {userEmailSelector, userInfoSelector}  from '@ec-oem/ec.oem.oa3.ui.common/selectors'
import { userProfileIdSelector } from "./ActivateUser.selectors";

export function* activateSaga() {
    while (true) {
        const {securityGuid,pin}= yield take(CHECK_ACTIVATION);
        yield put(setActivationStatus(ActivationStates.NOT_STARTED))
        const config = yield ApplicationConfig.config;
        const wizardHeaders = yield call(wizardApiHeaders);
        const userInfo = yield select((state) => userInfoSelector(state));
        const userProfileId = yield select((state)=>userProfileIdSelector(state));
        let data = JSON.stringify({emailGuid:securityGuid,securityPin:pin,isUserAccepted:true,isPageLoadCondition:false});

        const mdosCallConfig = {Config : {
            url : `${config.wizard_api_path}/OnboardModernized/UpdateAADEmail`,
            method : 'POST',
            data : JSON.stringify({AADLoginCredential:userInfo.idToken.preferred_username, UPN:userInfo.idToken.preferred_username, UserProfileId:userProfileId}),
            RequestHeaders:wizardHeaders
        }}

        const response = yield call(InvokeUrl, { Config: { url: `${config.oa_api.url}/activate/UserActivation`, method: 'POST', data:  data,RequestHeaders: [
            {
                key: [RequestHeaderConstants.SubscriptionKey],
                value: `${config.oa_api.Subscriptions_Headers.internal_pass_through}`
                        }
        ]}});
        
        if (response.status === 200) {
            const data = yield apply(response, response.json);
            if(data.result){
                yield call(InvokeUrl, mdosCallConfig);
                yield put(setActivationStatus(ActivationStates.SUCCESS))
            }
            else{
                if(data.messageCode && MessageCodesToStatusMapping[data.messageCode])
                    yield put(setActivationStatus(MessageCodesToStatusMapping[data.messageCode]));
                else  
                    yield put(setActivationStatus(ActivationStates.FAILURE));
            }
        }
        else  
            yield put(setActivationStatus(ActivationStates.FAILURE));
 }
}


export function* checkUserAcceptanceSaga() {
    while (true) {
        const {securityGuid}= yield take(CHECK_USER_ACCEPTANCE);
        const loggedInEmail = yield select(userEmailSelector);
        const config = yield ApplicationConfig.config;
        let data = JSON.stringify({emailGuid:securityGuid});
        const response = yield call(InvokeUrl, { Config: { url: `${config.oa_api.url}/activate/UserActivation`, method: 'POST', data:  data,RequestHeaders: [
            {
                key: [RequestHeaderConstants.SubscriptionKey],
                value: `${config.oa_api.Subscriptions_Headers.internal_pass_through}`
                            }]}});
        if (response.status === 200) {
            const data = yield apply(response, response.json)

            let messageCode = data.messageCode;
            let message = data.message;
            let userProfileId = data.userProfileId;

            if(userProfileId)
            yield put(setUserProfileId(userProfileId));

            let isAADEmail = yield call(isAADEmailCheck);
            if(!isAADEmail){
                yield put(setActivationStatus(ActivationStates.MSA_EMAIL));
                continue;
            }
            if(messageCode && message)
            {
                let messageFromResponse = ActivateUserUtil.getStatusFromMessage(message);
                switch(messageCode)
                {
                    case 'MSG_1_1_12':
                            yield put(setDuplicateAccountEmail(messageFromResponse));
                            yield put(setActivationStatus(MessageCodesToStatusMapping[messageCode]));
                        break;
                    case 'MSG_1_1_13':
                            if(messageFromResponse==="Suspended")
                            {
                                yield put(setActivationStatus(ActivationStates.SUSPENDED_USER));
                            }
                            else{
                                yield put(setActivationStatus(MessageCodesToStatusMapping[messageCode]));
                            }
                            yield put(setUserAccountStatus(messageFromResponse));
                          
                        break;
                    case 'MSG_1_1_14':
                            yield put(setExpectedContactEmail(messageFromResponse))
                            // commenting code to check if contactEmail is same as the loginCredential. Since this condition is not required to be satisfied in all cases.
                            // if email not matching then error otherwise mark as not started
                          //  if(loggedInEmail && loggedInEmail != messageFromResponse)
                            //    yield put(setActivationStatus(MessageCodesToStatusMapping[messageCode]));
                            //else
                             //  { 
                                yield put(setUserAcceptance(true));
                                yield put(setActivationStatus(ActivationStates.NOT_STARTED))
                               //}
                        break;
                    default:
                        yield put(setActivationStatus(MessageCodesToStatusMapping[messageCode]));
                }
            }
            else{
               
                if(data.result)
                {
                    yield put(setUserAcceptance(true));
                    yield put(setActivationStatus(ActivationStates.NOT_STARTED))
                }
                else  
                {
                    yield put(setUserAcceptance(false));
                    yield put(setActivationStatus(ActivationStates.INVALID_USER))
                }
            }
        }
        else  
            yield put(setActivationStatus(ActivationStates.FAILURE));
    }
}

export function* wizardApiHeaders(){
    const userInfo = yield select((state) => userInfoSelector(state));
    return [
		{
			key: 'appAuthFullTicket',
			value: btoa([0, 0, 0].join('|'))
		},
		{
			key: 'LoginCredential',
			value: userInfo && userInfo.idToken.preferred_username
		},
		{
			key: 'ObjectIdentifier',
			value: userInfo && userInfo.idToken.oid
		},
		{
			key: 'TenantId',
			value: userInfo && userInfo.idToken.tid
		}
	];
}

export function* isAADEmailCheck(){
    const config = yield ApplicationConfig.config;
    let MSA_TenantIds = config.MSA_TenantIds;
    const userInfo = yield select((state) => userInfoSelector(state));
    let TenantId = userInfo && userInfo.idToken.tid
    if(MSA_TenantIds.includes(TenantId))
    return false;
    else return true;
}

export function* checkResendActivationEmailSaga() {
    while(true)
    {
        const {securityGuid}= yield take(CHECK_RESEND_ACTIVATION_EMAIL);
        const config = yield ApplicationConfig.config;
        let data = JSON.stringify({resendActivationEmail:{emailGuid:securityGuid}});
        const response = yield call(InvokeUrl, { Config: { url: `${config.oa_api.url}/activate/ResendActivationEmail`, method: 'POST', data:  data,RequestHeaders: [
            {
                key: [RequestHeaderConstants.SubscriptionKey],
                value: `${config.oa_api.Subscriptions_Headers.internal_pass_through}`
                
            }]}});
            
      

        if (response.status === 200) {
            const responseData = yield response.json();

            yield put(setMaskedEmail(responseData.maskedEmail));
            yield put(setActivationStatus(ActivationStates.RESEND_ACTIVATION_EMAIL_SUCCESS))
        }
        else{
            yield put(setActivationStatus(ActivationStates.FAILURE))
        }

    }
}
