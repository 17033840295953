import { take, put } from "redux-saga/effects";
import { INITIALIZE_APP } from "../actions";
import ApplicationConfig from "../utility/applicationConfig";
import {
  initializeAppInsights,  
  setContactEmail
} from "@ec-oem/ec.oem.oa3.ui.common/actions";

export function* appStartSaga() {
  while (true) {
    yield take(INITIALIZE_APP);    
    const config = yield ApplicationConfig.config;
    yield put(initializeAppInsights(config.appinsights_InstrumentationKey)); 
    yield put(setContactEmail(config.contactEmail));
  }
}
