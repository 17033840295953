import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import { SET_ACTIVATION_STATUS, SET_USER_ACCEPTANCE,SET_DUPLICATE_ACCOUNT_EMAIL,SET_EXPECTED_CONTACT_EMAIL, SET_USER_ACCOUNT_STATUS, SET_USER_PROFILE_ID ,SET_MASKED_EMAIL} from './ActivateUser.actions';


export const currentActivationStatus = createReducer(null,{
    [SET_ACTIVATION_STATUS](state,{status}){
        return fromJS(status);
    }
});

export const userAcceptanceStatus = createReducer(null,{
    [SET_USER_ACCEPTANCE](state,{status}){
        return fromJS(status);
    }
});

export const duplicateEmail = createReducer(null,{
    [SET_DUPLICATE_ACCOUNT_EMAIL](state,{email}){
        return fromJS(email);
    }
});

export const expectedContactEmail = createReducer(null,{
    [SET_EXPECTED_CONTACT_EMAIL](state,{expectedContactEmail}){
        return fromJS(expectedContactEmail);
    }
});


export const userAccountStatus = createReducer(null,{
    [SET_USER_ACCOUNT_STATUS](state,{userAccountStatus}){
        console.log(userAccountStatus)
        return userAccountStatus;
    }
});

export const userProfileId = createReducer(null,{
    [SET_USER_PROFILE_ID](state,{userProfileId}){
        return userProfileId;
    }
});

export const maskedEmail = createReducer(null,{
    [SET_MASKED_EMAIL](state,{maskedEmail}){
        console.log(maskedEmail)
        return maskedEmail;
    }
});
