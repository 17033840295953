import "babel-polyfill";
import "react-app-polyfill/ie11";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import * as serviceWorker from "@ec-oem/ec.oem.oa3.ui.common/utility/registerServiceWorker";
import { getStore } from "./app/common/utility/store";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { localizationData } from "./resources/localization/localizationData";
import { LanguageProvider } from "@ec-oem/ec.oem.oa3.ui.common/components/LanguageProvider";
import ApplicationConfig from "./app/common/utility/applicationConfig";
import { AzureAD } from "@ec-oem/ec.oem.oa3.ui.core/components/Auth";
import { ProgressIndicator } from "office-ui-fabric-react";
import { ErrorBoundary } from "@ec-oem/ec.oem.oa3.ui.common/components";
const App = React.lazy(() => import("./app/App"));

initializeIcons();


ApplicationConfig.config.then(config => {
  const store = getStore();
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<ProgressIndicator />}>
      <LanguageProvider localization ={localizationData}>
          <ErrorBoundary>
            <AzureAD msal_config={config.MSAL_info} componentToRender={App} />
          </ErrorBoundary>
          </LanguageProvider>
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );

  serviceWorker.register();
  return config;
});
